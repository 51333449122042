import { Descriptions, Row, Col, Image } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { Type, ReturnAccount } from '../types';
import type { IAfterSaleInfo } from '../types';

const { Item } = Descriptions;

const labelStyle = {
  width: '90px',
  justifyContent: 'flex-end',
};

const list = [
  {
    label: '售后编号',
    key: 'mallReturnOrderNo',
  },
  {
    label: '售后类型',
    key: 'type',
  },
  {
    label: '售后原因',
    key: 'reason',
  },
  {
    label: '申请时间',
    key: 'applyTime',
  },
  {
    label: '申请说明',
    key: 'illustration',
  },
  {
    label: '关联订单编号',
    key: 'saleOrderNos',
  },
  {
    label: '退回仓库',
    key: 'warehouseName',
  },
  {
    label: '退回路径',
    key: 'returnAccount',
  },
  {
    label: '确认退款时间',
    key: 'refundTime',
  },
];

export const AfterSaleInfo: React.FC<{ afterSaleInfo: IAfterSaleInfo; }> = observer((props) => {
  const { afterSaleInfo, afterSaleInfo: { voucher = '' }} = props;
  const imageList = voucher?.split(',') || [];
  return (
    <div>
      <Descriptions column={4}>
        {list?.map((el) => {
          return (
            <Item
              key={el.key}
              label={el.label}
              labelStyle={labelStyle}
              span={el.key === 'refundTime' ? 4 : 1}
            >
              {el.key === 'type'
                ? (
                  <span>
                    {Type[afterSaleInfo[el.key]]}
                  </span>
                ) : el.key === 'returnAccount'
                  ? (
                    <span>
                      {ReturnAccount[afterSaleInfo[el.key]]}
                    </span>
                  ) : (
                    <span>
                      {afterSaleInfo[el.key]}
                    </span>
                  )}
            </Item>
          );
        })}
        <Item
          label="售后凭证"
          labelStyle={labelStyle}
          span={4}
        >
          <Row gutter={[
            8,
            8,
          ]}
          >
            {
              imageList?.map((el) => {
                return (
                  
                  <Col key={nanoid()}>
                    <span>
                      {
                        el ? (
                          <Image
                            height={56}
                            src={el}
                            width={56}
                          />
                        ) : null
                      }
                    </span>
                  </Col>
                );
              })
            }
            
          </Row>
        </Item>
      </Descriptions>
    </div>
  );
});
