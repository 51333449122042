interface IDetailInfo {
  actualAmount: number;
  actualNum: number;
  applyAmount: number;
  applyNum: number;
  applyTime: string;
  auditTime: string;
  buyerName: string;
  illustration: string;
  mallReturnOrderId: number;
  mallReturnOrderNo: string;
  mobile: string;
  reason: string;
  receiveAddress: string;
  receiveMobile: string;
  receiveTime: string;
  receiverName: string;
  refundAmount: number;
  refundNum: number;
  refundTime: string;
  remark: string;
  returnAccount: number;
  sendTime: string;
  status: number;
  type: number;
  voucher: string;
  warehouseName: string;
  courierVo: ICourierVo;
  detailVoList: IDetailVoList[];
  saleOrderNos: string[];
  cancelTime: string;
  returnDetailVos: IReturnDetailVos[];
  actualReturnPostAmount: number;
  refundPostAmount: number;
  actualReturnFinanceServiceAmount: number;
  returnFinanceServiceAmount: number;
}

interface ICourierVo {
  courierName: string;
  courierOrderNo: string;
  deliverGoodsTime: string;
  totalNum: number;
  courierDetailListVoList: Array<{
    status: string;
    statusCode?: number;
    statusTime: string;
    trace: string;
    waybillCode: string;
  }>;
  detailVoList: Array<{
    num: number;
    pic: string;
  }>;
}

interface IDetailVoList {
  applyAmount: number;
  applyNum: number;
  buyNum: number;
  goodsName: number;
  goodsSkuId: number;
  goodsSpec: string;
  picUrl: string;
  returnedNum: number;
  sellerOuterNo: string;
}

interface IAfterSaleInfo {
  mallReturnOrderNo: string;
  type: number;
  reason: string;
  applyTime: string;
  illustration: string;
  saleOrderNos: number[];
  warehouseName: string;
  returnAccount: string;
  refundTime: string;
  voucher: string;
}

enum ReturnAccount {
  '余额支付' = 1,
  '微信',
  '支付宝'
}

enum TimeList {
  'applyTime',
  'auditTime',
  'sendTime',
  'receiveTime',
  'refundTime',
}

enum Status {
  '待审核',
  '待门店退货',
  '待网仓收货',
  '待确认退款',
  '退款成功',
  '退款关闭',
}

enum Type {
  '退款' = 1,
  '退货退款'
}

interface ISummaryData {
  actualAmount: number;
  actualNum: number;
  applyAmount: number;
  applyNum: number;
  actualReturnPostAmount: number;
  refundPostAmount: number;
  actualReturnFinanceServiceAmount: number;
  returnFinanceServiceAmount: number;
}

interface IReceiveInfo {
  receiverName: string;
  receiveMobile: string;
  receiveAddress: string;
}

interface IReturnDetailVos {
  productName: string;
  amount: number;
  returnedAmount: number;
}

export { IDetailInfo, ICourierVo, IDetailVoList, ReturnAccount, Status, TimeList, IAfterSaleInfo, Type, ISummaryData, IReceiveInfo, IReturnDetailVos };
