import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { CollapsePanel } from '../../components';
import { ReturnLogisticModal } from '../returnLogisticsModal';
import { AfterSaleInfo } from './afterSaleInfo';
import { Courier } from './courier';
import { GoodsInfo } from './goodsInfo';
import styles from './index.less';
import { Log } from './Log';
import { StepStatus } from './step';
import { AfterSaleDetailStore } from './store';

const detailStore = new AfterSaleDetailStore();

const AfterSaleDetail: React.FC<{ store: AfterSaleDetailStore; }> = observer(() => {
  const {
    status,
    spinning,
    timeList,
    afterSaleInfo,
    courierVo,
    productGridModel,
    logGrid,
    summary,
    cancelApplication,
    receiveInfo,
    openCourier,
    returnLogisticsStore,
    onCancel,
    returnDetailVos,
  } = detailStore;

  const list = [
    {
      key: '售后信息',
      content: <AfterSaleInfo afterSaleInfo={afterSaleInfo}/>,
    },
    {
      key: '物流信息',
      content: <Courier courierInfoVos={courierVo}/>,
    },
    {
      key: '商品信息',
      content: (
        <GoodsInfo
          productGridModel={productGridModel}
          returnDetailVos={returnDetailVos}
          summary={summary}
        />),
    },
    {
      key: '协商记录',
      content: (<Log logGrid={logGrid}/>),
    },
  ];
  return (
    <div className={styles.pageContainer}>
      <Spin
        size="large"
        spinning={spinning}
        tip="数据加载中..."
      >
        <div className={styles.page}>
          <StepStatus
            cancelApplication={cancelApplication}
            openCourier={openCourier}
            orderStatus={status}
            receiveInfo={receiveInfo}
            timeList={timeList}
            type={afterSaleInfo.type}
          />
          {
            list?.map((el, index) => {
              if (status < 2 && index === 1) {
                return null;
              }
              return (
                <CollapsePanel
                  key={el.key}
                  title={el.key}
                >
                  {el.content}
                </CollapsePanel>
              );
            })
          }
        </div>
      </Spin>
      <ReturnLogisticModal store={returnLogisticsStore}/>
    </div>
  );
});

export default AfterSaleDetail;
