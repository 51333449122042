import { CopyOutlined } from '@ant-design/icons';
import { Steps, Button, Space, message } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import type { IReceiveInfo } from '../types';
import { TimeList } from '../types';
import styles from './index.less';
import { calcTimeDiffDisplay } from '../../../../utils/tools';

const { Step } = Steps;

const stepsGroup = [
  {
    name: '买家申请退款',
    icon: 'icon-bzdgl',
    timeName: 'applyTime',
  },
  {
    name: '平台审核',
    icon: 'icon-d_finance',
    timeName: 'auditTime',
  },
  {
    name: '买家退货',
    icon: 'icon-register_purchaser',
    timeName: 'sendTime',

  },
  {
    name: '网仓收货',
    icon: 'icon-shjc',
    timeName: 'receiveTime',
  },
  {
    name: '售后完成',
    icon: 'icon-gysdhhsbb',
    timeName: 'refundTime',
  },
];

const stepsGroupOnlyReturnMoney = [
  {
    name: '买家申请退款',
    icon: 'icon-bzdgl',
    timeName: 'applyTime',
  },
  {
    name: '平台审核',
    icon: 'icon-d_finance',
    timeName: 'auditTime',
  },
  {
    name: '售后完成',
    icon: 'icon-gysdhhsbb',
    timeName: 'refundTime',
  },
];

const closeStepsGroup = [
  {
    name: '申请售后',
    icon: 'icon-bzdgl',
    timeName: 'applyTime',
  },
  {
    name: '售后关闭',
    icon: 'icon-close_over',
    timeName: 'cancelTime',
  },
];

export const StepStatus: React.FC<{
  type: number;
  orderStatus: number;
  timeList: {
    applyTime: string;
    auditTime: string;
    sendTime: string;
    receiveTime: string;
    refundTime: string;
    cancelTime: string;
  };
  receiveInfo: IReceiveInfo;
  cancelApplication: () => void;
  openCourier: () => void;
}> = observer((props) => {
  const { orderStatus, timeList, cancelApplication, receiveInfo, openCourier, type } = props;
  const rendList = {
    close: closeStepsGroup,
    normal: stepsGroup,
    onlyReturn: stepsGroupOnlyReturnMoney,
  };
  return (
    <div className={styles.stepWrapper}>
      <div style={orderStatus !== 5 ? { margin: '0px 132px' } : { margin: '0px 300px' }}>
        <Steps>
          {rendList[orderStatus !== 5 ? (type === 1 ? 'onlyReturn' : 'normal') : 'close']?.map((item, index) => {
            return (
              <Step
                description={timeList[item.timeName] || ''}
                icon={<i className={item.icon}/>}
                key={nanoid()}
                status={timeList[item.timeName] ? 'finish' : 'wait'}
                title={item.name}
              />
            );
          })}
        </Steps>
      </div>
      <BannerElement
        auditTime={timeList.auditTime}
        onCancel={cancelApplication}
        openCourier={openCourier}
        orderStatus={orderStatus}
        receiveInfo={receiveInfo}
      />
    </div>
  );
});

const BannerElement: React.FC<{
  orderStatus: number;
  onCancel: () => void;
  receiveInfo: IReceiveInfo;
  openCourier: () => void;
  auditTime?: string;// 平台审核时间
  applyTime?: string;// 申请时间
}> = observer((props) => {
  const { orderStatus, onCancel, receiveInfo, openCourier, auditTime, applyTime } = props;

  const countDownSeconds = React.useMemo(() => {
    if (orderStatus === 1 && auditTime) {
      const seconds = moment(auditTime).add(5, 'days')
        .diff(moment(), 'seconds');
      return seconds > 0 ? seconds : 0;
    }
    if (orderStatus === 0 && applyTime) {
      const seconds = moment(applyTime).add(1, 'days')
        .diff(moment(), 'seconds');
      return seconds > 0 ? seconds : 0;
    }

    return 0;
  }, [
    auditTime,
    orderStatus,
  ]);

  return (
    <div className={styles.banner}>
      {
        orderStatus === 0 && (
          <>
            <div className={styles.bannerState}>
              买家申请退款，等待平台审核
            </div>
            <div className={styles.timeLimit}>
              （如平台在
              {calcTimeDiffDisplay(countDownSeconds)}
              内未处理，本次售后自动审核通过）
            </div>
            <div>
              <Button onClick={onCancel}>
                撤销申请
              </Button>
            </div>
          </>
        )
      }
      {
        orderStatus === 1 && (
          <>
            <div className={styles.bannerState}>
              平台同意退货，等待买家退货
            </div>
            <div className={styles.timeLimit}>
              （如买家在
              {calcTimeDiffDisplay(countDownSeconds)}
              内未填写退回物流，本次售后自动关闭）
            </div>
            <div className={styles.btnLine}>
              <Space>
                <Button
                  onClick={openCourier}
                  type="primary"
                >
                  填写退回物流
                </Button>
                <Button onClick={onCancel}>
                  撤销申请
                </Button>
              </Space>
            </div>
            <div className={styles.address}>
              <span>
                {`退货请寄回地址：${receiveInfo.receiverName} ${receiveInfo.receiveMobile} ${receiveInfo.receiveAddress}`}
              </span>
              <CopyToClipboard
                onCopy={() => {
                  message.success('复制成功');
                }}
                text={`${receiveInfo.receiverName} ${receiveInfo.receiveMobile} ${receiveInfo.receiveAddress}`}
              >
                <i
                  className={`icon-fz001 ${styles.copy}`}
                />
              </CopyToClipboard>
            </div>
          </>
        )
      }
      {
        orderStatus === 2 && (
          <div className={`${styles.bannerState} ${styles.mbUnset}`}>
            买家已退货，等待网仓收货
          </div>
        )
      }
      {
        orderStatus === 3 && (
          <div className={`${styles.bannerState} ${styles.mbUnset}`}>
            网仓已收货，等待平台退款
          </div>
        )
      }
      {
        orderStatus === 4 && (
          <div className={`${styles.bannerState} ${styles.mbUnset}`}>
            售后完成
          </div>
        )
      }
      {
        orderStatus === 5 && (
          <div className={`${styles.bannerState} ${styles.mbUnset}`}>
            售后关闭
          </div>
        )
      }
    </div>
  );
});

export default StepStatus;
