import type { EgGridModel } from 'egenie-utils';
import { EgGrid } from 'egenie-utils';
import React from 'react';
import type { IReturnDetailVos, ISummaryData } from '../types';
import styles from './index.less';

export const GoodsInfo: React.FC<{ productGridModel: EgGridModel; summary: ISummaryData; returnDetailVos: IReturnDetailVos[]; }> = (props) => {
  const {
    productGridModel,
    summary: {
      applyNum,
      applyAmount,
      actualNum,
      actualAmount,
      actualReturnPostAmount,
      refundPostAmount,
      actualReturnFinanceServiceAmount,
      returnFinanceServiceAmount,
    },
    returnDetailVos,
  } = props;
  const returnAmount = returnDetailVos?.reduce((pre, next) => {
    return pre + next.amount;
  }, 0);
  const actualReturnAmount = returnDetailVos?.reduce((pre, next) => {
    return pre + next.returnedAmount;
  }, 0);
  return (
    <div className={styles.descriptionWrap}>
      <div style={{ height: '256px' }}>
        <EgGrid store={productGridModel}/>
      </div>
      <div className={styles.calc}>
        <div className={styles.row}>
          <div className={styles.label}>
            申请退款数量：
          </div>
          <div className={styles.value}>
            {applyNum}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            申请退款金额：
          </div>
          <div className={styles.value}>
            ¥
            {(applyAmount + refundPostAmount + returnFinanceServiceAmount + returnAmount).toFixed(2)}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            实退数量：
          </div>
          <div className={styles.value}>
            {actualNum}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            实退金额：
          </div>
          <div className={`${styles.value} ${styles.colorRed}`}>
            ¥
            {(actualAmount + actualReturnPostAmount + actualReturnFinanceServiceAmount + actualReturnAmount).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};
