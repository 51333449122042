import { message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { EgGridModel, ImgFormatter } from 'egenie-utils';
import { action, observable } from 'mobx';
import moment from 'moment';
import React from 'react';
import { api } from '../../../utils';
import { ReturnLogisticsStore } from '../returnLogisticsModal/store';
import styles from './index.less';
import type { IAfterSaleInfo, ICourierVo, IDetailInfo } from './types';

export class AfterSaleDetailStore {
  constructor() {
    const params = location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const id = search.get('mallReturnOrderId');
    this.onShow(Number(id));
  }

  public returnLogisticsStore = new ReturnLogisticsStore({ parent: this });

  @observable public spinning = false;

  @observable public receiveInfo = {
    receiverName: '',
    receiveMobile: '',
    receiveAddress: '',
  }; // 收货信息

  // 时间
  @observable public timeList = { // 步骤进行时间
    applyTime: '',
    auditTime: '',
    sendTime: '',
    receiveTime: '',
    refundTime: '',
    cancelTime: '',
  };

  // 售后信息
  @observable public afterSaleInfo: IAfterSaleInfo = { // 售后信息
    mallReturnOrderNo: '',
    type: 1,
    reason: '',
    applyTime: '',
    illustration: '',
    saleOrderNos: [],
    warehouseName: '',
    returnAccount: '',
    refundTime: '',
    voucher: '',
  };

  // 物流
  @observable public courierVo: ICourierVo = {
    courierName: '',
    courierOrderNo: '',
    deliverGoodsTime: '',
    totalNum: 0,
    courierDetailListVoList: [],
    detailVoList: [],
  };

  // 汇总信息
  @observable public summary = {
    actualAmount: 0,
    actualNum: 0,
    applyAmount: 0,
    applyNum: 0,
    refundPostAmount: 0,
    actualReturnPostAmount: 0,
    actualReturnFinanceServiceAmount: 0,
    returnFinanceServiceAmount: 0,
  };

  @observable public returnDetailVos = [];

  @observable public mallReturnOrderId: number; // 售后订单id

  @observable public status: number;

  public productGridModel = new EgGridModel({
    columns: [
      {
        key: 'productInfo',
        name: '商品信息',
        formatter: ({ row }) => {
          return (
            <div className={styles.productFlex}>
              <ImgFormatter
                height={56}
                value={row.picUrl}
                width={56}
              />
              <div className={styles.textInfo}>
                <div className={styles.firstLineText}>
                  {row.goodsName}
                </div>
                <div>
                  货号：
                  {row.sellerOuterNo}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        key: 'goodsSpec',
        name: '商品规格',
        width: 200,
      },
      {
        key: 'buyNum',
        name: '下单数量',
        width: 140,
      },
      {
        key: 'applyNum',
        name: '售后数量',
        width: 140,
      },
      {
        key: 'returnedNum',
        name: '实退数量',
        width: 140,
      },
      {
        key: 'applyAmount',
        name: '售后金额',
        formatter: ({ row }) => {
          return (
            <span>
              ¥
              {row.applyAmount}
            </span>
          );
        },
      },
    ].map((el) => {
      return {
        ...el,
        resizable: true,
      };
    }),
    rows: [],
    primaryKeyField: 'id',
    rowHeight: 72,
    showCheckBox: false,
    showPager: false,
    showReset: false,
  });

  public logGrid = new EgGridModel({
    columns: [
      {
        key: 'operationType',
        name: '操作类型',
      },
      {
        key: 'operationResult',
        name: '操作内容',
      },
      {
        key: 'operatorShowName',
        name: '操作人',
      },
      {
        key: 'lastUpdated',
        name: '操作时间',
        formatter: ({ row }) => {
          return (
            <div>
              {moment(row.lastUpdated)
                .format('YYYY-MM-DD HH:mm:ss')}
            </div>
          );
        },
      },
    ],
    primaryKeyField: 'id',
    rowHeight: 32,
    forceRowClick: true,
    showCheckBox: false,
    pageSize: 10,
    showSelectedTotal: false,
    showReset: false,
    showNormalEmpty: true,
    showPager: false,
  });

  @action public queryDetailInfo = async(): Promise<void> => {
    this.spinning = true;
    const res: BaseData<IDetailInfo> = await request({ url: `${api.queryAfterSaleDetail}?mallReturnOrderId=${this.mallReturnOrderId}` });
    const {
      status,
      applyTime,
      auditTime,
      sendTime,
      receiveTime,
      refundTime,
      mallReturnOrderNo,
      type,
      reason,
      illustration,
      saleOrderNos,
      warehouseName,
      returnAccount,
      voucher,
      courierVo,
      detailVoList,
      actualAmount,
      actualNum,
      applyAmount,
      applyNum,
      receiveAddress,
      receiverName,
      receiveMobile,
      cancelTime,
      returnDetailVos,
      actualReturnPostAmount,
      refundPostAmount,
      actualReturnFinanceServiceAmount,
      returnFinanceServiceAmount,
    } = res.data;
    Object.assign(this, {
      status,
      timeList: {
        applyTime,
        auditTime,
        sendTime,
        receiveTime,
        refundTime,
        cancelTime,
      },
      afterSaleInfo: {
        mallReturnOrderNo,
        type,
        reason,
        applyTime,
        illustration,
        saleOrderNos,
        warehouseName,
        returnAccount,
        refundTime,
        voucher,
      },
      summary: {
        actualAmount,
        actualNum,
        applyAmount,
        applyNum,
        actualReturnPostAmount,
        refundPostAmount,
        actualReturnFinanceServiceAmount,
        returnFinanceServiceAmount,
      },
      receiveInfo: {
        receiveAddress,
        receiverName,
        receiveMobile,
      },
      courierVo,
      returnDetailVos,
    });
    this.productGridModel.rows = detailVoList || [];

    const resLog = await request<PaginationData<unknown>>({
      url: `${api.queryOrderLog}`,
      method: 'post',
      data: {
        page: 1,
        pageSize: 1000,
        id: this.mallReturnOrderId,
        tableName: 'mall_return_order',
      },
    });

    this.logGrid.rows = resLog.data?.list || [];
    this.logGrid.total = resLog.data?.totalCount || 0;
    this.spinning = false;
  };

  @action public onShow = (mallReturnOrderId: number) => {
    this.mallReturnOrderId = mallReturnOrderId;
    this.queryDetailInfo();
  };

  @action public onCancel = (): void => {
    Object.assign(this, { visible: false });
    this.logGrid.rows = [];
    this.productGridModel.rows = [];
  };

  // 撤销申请
  public cancelApplication = () => {
    Modal.confirm({
      title: '提示',
      content: '是否确认撤销该数据申请？',
      onOk: () => {
        return request<BaseData>({
          url: api.cancelAppli,
          method: 'GET',
          params: { mallReturnOrderId: this.mallReturnOrderId },
        })
          .then((res) => {
            message.success(res.info || '操作成功');

            // 刷新页面
            this.queryDetailInfo();
          });
      },
    });
  };

  // 打开填写物流弹窗
  public openCourier = (): void => {
    this.returnLogisticsStore.openModal({
      receivedAddress: this.receiveInfo.receiveAddress,
      receivedMobile: this.receiveInfo.receiveMobile,
      receivedUser: this.receiveInfo.receiverName,
      mallReturnOrderId: this.mallReturnOrderId,
      status: this.status,
      auditTimeStr: this.timeList.auditTime,
    });
  };

  // 填写物流后刷新页面
  public querySaleOrder = (): void => {
    this.queryDetailInfo();
  };
}
